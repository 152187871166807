import Input from "../Input";

const Claim = ({
  availableToClaim,
  claimValue,
  onChange,
  onClick,
  isError,
}) => {
  return (
    <div className="claim w-full lg:w-[520px] lg:flex-1 px-5 py-12 md:px-12 shrink mb-10 sm:mb-0">
      <div className=" mb-7 sm:mb-14">
        <p className="text-mainWhite text-[22px] sm:text-3xl font-medium lg:whitespace-nowrap">
          Available to Claim now:
        </p>
        <p className="text-mainBlue text-3xl font-bold">
          {availableToClaim} WWY
        </p>
      </div>
      <Input
        value={claimValue}
        isError={isError}
        onChange={onChange}
        onMaxClick={() => onChange(availableToClaim)}
      />
      <button
        onClick={onClick}
        className="bg-mainBlue rounded-large w-full py-5 mt-5"
      >
        CLAIM
      </button>
    </div>
  );
};

export default Claim;
