import { ReactComponent as WarningIcon } from "../../assets/alert.svg";

const Warning = () => {
  return (
    <div className="flex bg-[#282027] justify-center items-center gap-x-5 py-4">
      <WarningIcon />
      <p className="font-Inter text-base text-mainRed">
        WePad doesn’t support network selected in your wallet. Please change to
        Binance Smart Chain Network
      </p>
      <button className="w-full sm:w-auto border-2 border-mainWhite text-mainWhite rounded-large px-8 py-3.5 text-xs font-medium">
        CHANGE NETWORK
      </button>
    </div>
  );
};

export default Warning;
