import { ProgressBar, Step } from "react-step-progress-bar";
import { ReactComponent as RocketIcon } from "../../assets/rocket.svg";
import { ReactComponent as CheckMark } from "../../assets/checkMark.svg";
import { ReactComponent as Cross } from "../../assets/Cross.svg";
import { ReactComponent as Logo } from "../../assets/weway.svg";
import { ReactComponent as Flag } from "../../assets/progress-flag.svg";
import { ReactComponent as RecIcon } from "../../assets/rectangle.svg";
import "react-step-progress-bar/styles.css";
import moment from "moment";

const Info = ({ percent = 0, lockPercent, startDate, cliff, duration }) => {
  return (
    <div className="w-full sm:shrink shrink-0 lg:flex-2 bg-lightDark rounded-3xl sm:pb-[80px] lg:pb-0">
      <div className="flex flex-wrap text-lg	sm:justify-start	justify-center sm:text-3xl	text-mainWhite items-center py-12	sm:pl-12">
        <Logo />
        <p className="whitespace-nowrap pb-[4px] pl-2">{`(WWY) vesting info`}</p>
      </div>
      <div className="progressbar p-10 sm:p-16 sm:px-[5.5rem]">
        <span className="relative">
          <ProgressBar
            percent={percent}
            height={16}
            filledBackground="linear-gradient(90deg, #48D1CC 50%, #6241F8 100%)"
            unfilledBackground="#292B39"
            stepPositions={[0, lockPercent ? lockPercent : 50, 100]}
          >
            <Step transition="scale">
              {({ accomplished }) => (
                <div className="step relative">
                  {percent ? (
                    <div
                      className={`step-date font-Inter ${
                        percent ? "text-mainBlue" : "text-mainWhite"
                      } font-semibold md:text-xl absolute -top-8 -left-1/2`}
                    >
                      {moment.unix(startDate).format("DD.MM.YYYY")}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    className={`w-12 h-12 ${
                      percent ? "bg-mainBlue" : "bg-[#181a26]"
                    } rounded-full flex justify-center items-center border-4 border-mainGrey`}
                  >
                    <RocketIcon />
                  </div>
                  <div className="step-text md:mt-4 font-Inter text-mainWhite text-2xl absolute whitespace-nowrap -left-1/2 font-semibold	">
                    Start Cliff
                  </div>
                </div>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <div className="step relative">
                  {percent ? (
                    <div
                      className={`step-date font-Inter ${
                        accomplished ? "text-mainBlue" : "text-mainWhite"
                      } font-semibold	md:text-xl absolute -top-8 -left-1/2`}
                    >
                      {moment.unix(cliff).format("DD.MM.YYYY")}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    className={`w-12 h-12 bg-mainDark rounded-full flex justify-center items-center border-4  ${
                      accomplished ? "border-mainBlue" : "border-mainGrey"
                    }`}
                  >
                    {accomplished ? <CheckMark /> : <Cross />}
                  </div>
                  <div className="step-text md:mt-4 font-Inter text-mainWhite text-2xl absolute whitespace-nowrap -left-1/2 font-semibold	">
                    End Cliff / <br />
                    Start Vesting
                  </div>
                </div>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <div className="step relative">
                  {percent ? (
                    <div
                      className={`step-date font-Inter ${
                        accomplished ? "text-[#5F48F3]" : "text-mainWhite"
                      } font-semibold	md:text-xl	absolute -top-8 -left-1/2`}
                    >
                      {moment.unix(startDate + duration).format("DD.MM.YYYY")}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    className={`step-date font-Inter font-extrabold	w-12 h-12 rounded-full flex justify-center items-center border-4  text-sm	${
                      accomplished
                        ? "border-[#5F48F3] shadow-3xl shadow-[#7686FF] bg-[#3F42D4] text-mainWhite"
                        : `border-mainGrey bg-mainDark ${
                            percent ? "text-mainBlue" : "text-[#484A5A]"
                          }`
                    }`}
                  >
                    {percent ? percent : "0"}%
                  </div>
                  <div className="step-text md:mt-4 font-Inter text-mainWhite text-2xl absolute whitespace-nowrap -left-[80%] font-semibold">
                    End Vesting
                  </div>
                </div>
              )}
            </Step>
          </ProgressBar>
          {percent ? (
            <span className="absolute" style={{ left: `${percent}%` }}>
              <span className="horizontal-percent-flag">
                <div className="border h-[72px] border-[#3E404E] w-0 mt-4 mb-2.5" />
                <Flag />
                <div className="font-Inter text-mainWhite">
                  {moment
                    .unix(Math.floor(Date.now() / 1000))
                    .format("DD.MM.YYYY")}
                </div>
              </span>
              <span className="vertical-percent-flag">
                <div className="relative rounded-lg font-Inter font-medium text-mainWhite bg-[#252734] border-2 border-[#5F48F3] px-3">
                  22.10.2022
                  <span className="absolute -right-[7px] top-1/2 -translate-y-2/4">
                    <RecIcon />
                  </span>
                </div>
              </span>
            </span>
          ) : null}
        </span>
      </div>
    </div>
  );
};

export default Info;
