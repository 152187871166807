import { ReactComponent as Logo } from "../../assets/weway.svg";
import WalletModal from "../Modal";
import { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import { useWeb3React } from "@web3-react/core";
import Warning from "./Warning";

const Header = () => {
  const [openModal, setOpenModal] = useState();
  const { account, deactivate, active, chainId } = useWeb3React();
  const [chainError, setChainError] = useState(false);

  const acc = (
    account?.substring(0, 2) +
    "..." +
    account?.substring(account?.length - 4)
  ).toUpperCase();

  useEffect(() => {
    if (active) {
      if (chainId !== 56) setChainError(true);
    }
  }, [active, chainId]);

  return (
    <>
      <div className="bg-mainDark mb-9">
        <div className="lg:container flex flex-col sm:flex-row justify-between items-center mx-4 sm:mx-auto">
          <div className="pt-6 sm:pt-0">
            <Logo />
          </div>
          {!account ? (
            <button
              onClick={() => setOpenModal(true)}
              className="w-full sm:w-auto border-2 border-mainWhite text-mainWhite rounded-large px-8 py-3.5 text-xs font-medium	mx-6 my-7"
            >
              CONNECT WALLET
            </button>
          ) : (
            <Dropdown
              account={acc}
              disconnect={() => {
                deactivate();
                localStorage.removeItem("isWalletConnectedByMetamask");
                localStorage.removeItem("isWalletConnectedByWalletConnect");
              }}
            />
          )}
        </div>
        {chainError && <Warning />}
      </div>
      <WalletModal isOpen={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
};

export default Header;
