const Input = ({ value, onChange, onMaxClick, isError = false }) => {
  return (
    <div className="relative">
      <input
        value={value}
        onChange={({ target }) => onChange(target.value)}
        className={`bg-[#252734] border ${
          !isError ? "border-[#3C3E4D]" : "border-[#B26762]"
        } rounded-xl font-Inter text-xl text-mainWhite placeholder-[#545766] w-full p-4 outline-none caret-mainBlue`}
        placeholder={`1000 WWY`}
      />
      <button
        onClick={onMaxClick}
        className="absolute border-2 border-mainBlue rounded-md text-mainBlue hover:bg-mainBlue hover:text-mainDark py-1.5 px-3 right-18px top-2/4 -translate-y-2/4 text-sm"
      >
        MAX
      </button>
      {isError && (
        <div className="absolute -top-7 left-2.5 font-Inter text-[#B26762] text-base	font-medium	">
          Please, write the correct value
        </div>
      )}
    </div>
  );
};

export default Input;
