import Title from "./Title";
import Info from "./Info";
import Claim from "./Claim";
import Description from "./Description";
import { useWeb3React } from "@web3-react/core";
import WalletError from "./WalletError";
import TokenCards from "./TokenCards";
import { useEffect, useState } from "react";
import { ethers, utils } from "ethers";
import ABI from "../../abi/ABI.json";
import ConnectError from "./ConnectError";
import { NUMBER_REGEX } from "../../constants";

const Vesting = () => {
  const { account, active } = useWeb3React();
  const [vestingData, setVestingData] = useState();
  const [vestingPercent, setVestingPercent] = useState(0);
  const [lockPercent, setLockPercent] = useState(0);
  const [releasableAmount, setReleasableAmount] = useState(null);
  const [accountError, setAccountError] = useState(false);
  const [claimError, setClaimError] = useState(false);
  const [claimValue, setClaimValue] = useState("");

  const getReleasableAmount = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT_ADDRESS,
      ABI,
      provider
    );

    const scheduleId =
      await contract.computeVestingScheduleIdForAddressAndIndex(account, 0);

    const amount = await contract.computeReleasableAmount(scheduleId);

    setReleasableAmount(amount);
  };

  const getLockTimePercent = (data) => {
    const lockTime = data.cliff?.toNumber() - data.start?.toNumber();
    const vestingTime = data.duration?.toNumber();

    setLockPercent(Math.trunc((lockTime * 100) / vestingTime));
  };

  const getVestingPercent = (data) => {
    const currentDateUnix = Math.floor(Date.now() / 1000);
    const startDate = data?.start?.toNumber();

    const currentTime = currentDateUnix - startDate;
    const vestingTime = data?.duration?.toNumber();

    const percent = Math.trunc((currentTime * 100) / vestingTime);
    if (percent > 100) {
      setVestingPercent(100);
    } else {
      setVestingPercent(Math.trunc((currentTime * 100) / vestingTime));
    }
  };

  const getVestingData = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT_ADDRESS,
      ABI,
      provider
    );
    const data = await contract.getLastVestingScheduleForHolder(account);

    setVestingData(data);
    getLockTimePercent(data);
    getVestingPercent(data);
  };

  const claimTokens = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    if (
      !NUMBER_REGEX.test(String(claimValue).toLowerCase()) ||
      Number(claimValue.toString()) >
        Math.floor(utils.formatEther(releasableAmount))
    ) {
      return setClaimError(true);
    }
    setClaimError(false);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT_ADDRESS,
      ABI,
      signer
    );

    const value = utils.parseEther(claimValue.toString());

    const scheduleId =
      await contract.computeVestingScheduleIdForAddressAndIndex(account, 0);

    await contract.release(scheduleId, value.toString());
  };

  useEffect(() => {
    if (!account) {
      setVestingData(null);
      setVestingPercent(0);
      setLockPercent(0);
      setReleasableAmount(null);
      return;
    }

    getVestingData()
      .then(() => {
        setAccountError(false);
      })
      .catch(() => {
        setAccountError(true);
        setVestingData(null);
        setVestingPercent(0);
        setLockPercent(0);
        setReleasableAmount(null);
      });
    getReleasableAmount();
  }, [account, active]);

  return (
    <div className="lg:container">
      <div>
        <div className="flex flex-col	lg:flex-row items-center">
          <Title />
          <Description />
        </div>
        <div className="block sm:hidden">
          {!account ? <WalletError /> : accountError ? <ConnectError /> : null}
        </div>
        <TokenCards data={vestingData} releasableAmount={releasableAmount} />
        <div
          className={`flex flex-col ${
            !account || accountError ? "mb-[40px]" : null
          } lg:flex-row gap-y-6 lg:gap-y-0 gap-x-5`}
        >
          <Info
            percent={vestingPercent}
            lockPercent={lockPercent}
            startDate={vestingData?.start.toNumber()}
            cliff={vestingData?.cliff.toNumber()}
            duration={vestingData?.duration.toNumber()}
          />
          <div className="hidden sm:block">
            {!account ? (
              <WalletError />
            ) : accountError ? (
              <ConnectError />
            ) : null}
          </div>
          {account && !accountError && (
            <Claim
              availableToClaim={
                releasableAmount
                  ? Math.floor(utils.formatEther(releasableAmount))
                  : null
              }
              claimValue={claimValue}
              onChange={(value) => setClaimValue(value)}
              onClick={() => claimTokens()}
              isError={claimError}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Vesting;
