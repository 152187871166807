import { useState } from "react";
import { ReactComponent as ErrorrIcon } from "../../assets/error.svg";
import { ReactComponent as Socket } from "../../assets/socket.svg";
import WalletModal from "../Modal";

const WalletError = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className="relative wallet-error w-full lg:w-[520px] lg:flex-1 mt-6 sm:mt-0 px-5 p-12 md:px-12 md:pt-[55px] shrink mb-10 sm:mb-0">
        <div>
          <div className="flex align-left gap-x-2">
            <ErrorrIcon className="block sm:hidden w-6 h-6" />
            <p className="text-mainWhite text-xl	sm:text-3xl font-medium whitespace-nowrap pb-2 md:min-w-[420px]">
              Wallet not connected
            </p>
          </div>
          <p className="text-mainWhite font-Inter text-[16px] pt-5">
            Please connect your wallet
          </p>
        </div>
        <div className="mt-7 hidden sm:block">
          <Socket />
        </div>
        <button
          onClick={() => setOpenModal(true)}
          className="bg-mainBlue rounded-large w-full py-5 mt-9"
        >
          CONNECT WALLET
        </button>
        <span className="absolute hidden sm:block -top-4 left-12">
          <ErrorrIcon />
        </span>
      </div>
      <WalletModal isOpen={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
};

export default WalletError;
