const Card = ({ position, title, amount, image }) => {
  return (
    // todo padding
    <div className="card-wrapper flex py-6 pl-10 flex-1">
      <div className="mr-5">{image}</div>
      <div>
        <div className="text-mainWhite text-base font-Inter">{title}</div>
        <div className="text-mainBlue font-bold	">{amount}</div>
      </div>
      {position === 0 || position === 1 ? (
        <span className="card-dot-right" />
      ) : null}
      {position === 2 || position === 1 ? (
        <span className="card-dot-left" />
      ) : null}
    </div>
  );
};

export default Card;
