import Card from "./Card";
import { ReactComponent as WalletIcon } from "../../assets/wallet.svg";
import { ReactComponent as CoinsIcon } from "../../assets/coins.svg";
import { ReactComponent as SCIcon } from "../../assets/smart-contract.svg";
import { utils } from "ethers";

const TokenCards = ({ data, releasableAmount }) => {
  const cardsData = [
    {
      title: "Total Allocated",
      amount: `${
        data?.amountTotal
          ? Math.floor(utils.formatEther(data?.amountTotal))
          : "0"
      } WWY`,
      image: <WalletIcon />,
    },
    {
      title: "Total Unlocked",
      amount: `${
        releasableAmount
          ? Math.floor(utils.formatEther(releasableAmount)) +
            Math.floor(utils.formatEther(data?.released))
          : "0"
      } WWY`,
      image: <CoinsIcon />,
    },
    {
      title: "Total Claimed",
      amount: `${
        data?.released ? Math.floor(utils.formatEther(data?.released)) : "0"
      } WWY`,
      image: <SCIcon />,
    },
  ];

  return (
    <div className="flex flex-col md:flex-row mx-5 md:mx-auto gap-y-4 sm:gap-y-auto gap-x-16 lg:gap-x-24 mt-12 mb-11 flex-wrap">
      {cardsData.map((item, i) => {
        return (
          <Card
            key={item.title}
            position={i}
            title={item.title}
            amount={item.amount}
            image={item.image}
          />
        );
      })}
    </div>
  );
};

export default TokenCards;
