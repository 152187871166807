import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./assets/fonts/Druk Text Wide Cyr Bold.otf";
import "./assets/fonts/Druk Text Wide Cyr Heavy.otf";
import "./assets/fonts/Druk Text Wide Cyr Medium.otf";
import "./assets/fonts/Inter-Medium.ttf";
import { Web3ReactProvider } from "@web3-react/core";
import { providers } from "ethers";

const getLibrary = (provider) => {
  const library = new providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Web3ReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
