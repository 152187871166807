const Description = () => {
  return (
    <span className="font-Inter text-mainWhite text-base sm:text-xl px-5 pd:m-auto font-normal opacity-90 max-w-[590px] text-center lg:text-left w-full mt-[18px] lg:m-auto">
      If you participated in Private Sales, Airdrops, CEX Launchpads or other
      activities related to the WEWAY and want to bring the tokens to your
      balance, click Claim.
    </span>
  );
};

export default Description;
