import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import Header from "../components/Header";
import Vesting from "../components/Vesting";
import { connectors } from "../components/wallet/connectors";

const Home = () => {
  const { account, activate } = useWeb3React();

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage.getItem("isWalletConnectedByMetamask") === "true") {
        try {
          await activate(connectors.injected);
        } catch (error) {
          console.log(error);
        }
      }
      if (localStorage.getItem("isWalletConnectedByWalletConnect") === "true") {
        try {
          await activate(connectors.walletConnect);
        } catch (error) {
          console.log(error);
        }
      }
    };
    connectWalletOnPageLoad();
  }, []);

  return (
    <div className="relative md:h-[100vh]">
      <Header />
      <Vesting />
      <div
        className={`flex flex-col items-center gap-y-1.5 text-center pb-4 ${
          account ? "pt-4" : null
        }`}
      >
        <a
          className="cursor-pointer font-Inter text-[#48D1CC] text-base w-fit	"
          href={
            "https://docs.google.com/document/d/1cXPGimznrrd_dItdspcA_RyBeTlIBemJf-649-EBReM/edit?usp=sharing"
          }
        >
          Read Guide
        </a>
      </div>
    </div>
  );
};

export default Home;
