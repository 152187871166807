import Modal from "react-modal";
import { ReactComponent as Metamask } from "../../assets/metamask.svg";
import { ReactComponent as WalletConnect } from "../../assets/walletconnect.svg";
import { ReactComponent as Close } from "../../assets/close.svg";
import { connectors } from "../wallet/connectors";
import { useWeb3React } from "@web3-react/core";

const styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#1E202C",
    border: "3px solid #2B2D3B",
    borderRadius: "20px",
    padding: 50,
    maxWidth: "calc(100vw - 2rem)",
    maxHeight: "calc(100vh - 2rem)",
  },
  overlay: {
    background: "rgba(12, 12, 16, 0.8)",
    zIndex: 2,
  },
  title: {
    color: "white",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "120%",
    marginBottom: "24px",
  },
  metamask: {
    width: "100%",
    display: "flex",
    border: "2px solid #EEEEF3",
    borderRadius: "10px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    // color: "#EEEEF3",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 0",
  },
  walletConnect: {
    width: "100%",
    background: "#3B99FC",
    borderRadius: "10px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#EEEEF3",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    padding: "21px 0",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  closeButton: {
    position: "absolute",
    top: "23px",
    right: "23px",
    cursor: "pointer",
  },
};

const WalletModal = ({ isOpen, onClose }) => {
  const { activate } = useWeb3React();

  async function connectMetamask() {
    try {
      await activate(connectors.injected);
      localStorage.setItem("isWalletConnectedByMetamask", true);
    } catch (ex) {
      console.log(ex);
    }
    onClose();
  }

  async function connectWalletConnect() {
    try {
      await activate(connectors.walletConnect);
      localStorage.setItem("isWalletConnectedByWalletConnect", true);
    } catch (ex) {
      console.log(ex);
    }
    onClose();
  }

  return (
    <Modal ariaHideApp={false} style={styles} isOpen={isOpen}>
      <p style={styles.title} className="text-center sm:text-left">
        Connect your wallet
      </p>
      <div style={styles.buttonsWrapper}>
        <button
          onClick={connectMetamask}
          className="hover:bg-mainWhite text-mainWhite hover:text-mainDark"
          style={styles.metamask}
        >
          <Metamask />
          <span className="ml-2">METAMASK</span>
        </button>
        <button onClick={connectWalletConnect} style={styles.walletConnect}>
          <WalletConnect />
          <span className="ml-2">WalletConnect</span>
        </button>
      </div>
      <span style={styles.closeButton} onClick={onClose}>
        <Close />
      </span>
    </Modal>
  );
};

export default WalletModal;
