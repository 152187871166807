import { ReactComponent as ErrorrIcon } from "../../assets/error.svg";
import { ReactComponent as ErrorCloud } from "../../assets/error-cloud.svg";
import { useWeb3React } from "@web3-react/core";

const ConnectError = () => {
  const { deactivate } = useWeb3React();
  return (
    <div className="relative wallet-error w-full lg:w-[520px] mt-6 sm:mt-0 px-5 md:px-12 pt-10 p-12 basis-1/3 mb-10 sm:mb-0">
      <div>
        <div className="flex items-center gap-x-3">
          <ErrorrIcon className="block sm:hidden w-6 h-6" />
          <p className="text-mainWhite text-xl	sm:text-3xl font-medium md:min-w-[420px]">
            Try to connect another wallet
          </p>
        </div>
        <div className="text-mainWhite font-Inter text-sm pt-6">
          We didn't find any vesting data for this account
        </div>
      </div>
      <div className="mt-4 hidden sm:block">
        <ErrorCloud />
      </div>
      <button
        onClick={() => {
          deactivate();
          localStorage.removeItem("isWalletConnectedByMetamask");
          localStorage.removeItem("isWalletConnectedByWalletConnect");
        }}
        className="bg-mainBlue rounded-large w-full py-5 mt-8"
      >
        DISCONNECT
      </button>
      <span className="absolute hidden sm:block -top-4 left-12">
        <ErrorrIcon />
      </span>
    </div>
  );
};

export default ConnectError;
