import { useEffect, useRef, useState } from "react";
import { ReactComponent as WalletIcon } from "../../assets/whitewallet.svg";
import { ReactComponent as Arrow } from "../../assets/arrow_up.svg";

const Dropdown = ({ account, disconnect }) => {
  const [isOpen, setIsOpen] = useState();
  const node = useRef();

  const clickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className="w-full sm:w-auto relative flex flex-col mx-6 my-7"
      ref={node}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex gap-x-3 items-center  justify-center sm:justify-start w-full sm:w-auto border-2 border-mainWhite text-mainWhite rounded-large px-8 py-3.5 text-xs font-medium"
      >
        {account}
        <WalletIcon />
        <Arrow className={isOpen ? "origin-center rotate-180" : undefined} />
      </button>
      {isOpen && (
        <div>
          <button
            onClick={disconnect}
            className="absolute w-full z-[1] top-[110%] border-2 border-[#242427] bg-[#19191C] rounded-large text-white py-[11px] px-9 text-xs "
          >
            DISCONNECT
          </button>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
